// Mood Board Color
export const BG_PRIMARY = '#EFF4EC';
export const BG_SECONDARY = '#C9C3A9';
export const BG_ALTERNATIVE = '#576536';
export const BG_OPACITY = `#80562b17`;
// Text Color
export const TEXT_PRIMARY = '#000000';
export const TEXT_SECONDARY = '#695548'; // Warana Coklat Tua sama dengan BG_ALTERNATIVE
export const TEXT_ALTERNATIVE = '#805D20'; // Alternative Color
// Music & Navigation Color
export const NAVIGATION_COLOR = '#98512D'; // Warna BG
export const ICON_NAVIGATION_COLOR = 'white'; // Warna Iconnya
// Drawer Color
export const DRAWER_COLOR = 'rgb(116 84 63)';
export const BORDER_COLOR = '#EDE0D1';
export const FOOTER_COLOR = '#7e6c696e';
// Default Button Color
export const BUTTON_GRADIENT = `linear-gradient(to left, #4A4935, #D4BB90 140%)`;
export const BUTTON_PROPS = {
  _active: { borderColor: 'transparent' },
  _hover: { borderColor: 'transparent' },
  _focus: { borderColor: 'transparent' },
};
export const BUTTON_PADDING_PROPS = {
  ...BUTTON_PROPS,
  variant: 'solid',
  padding: '4px 16px',
  borderRadius: '16px',
  fontWeight: 'normal',
  background: BUTTON_GRADIENT,
  color: 'white',
  size: 'sm',
  boxShadow: 'sm',
  fontSize: 'md',
};

export const DEFAULT_BUTTON_PROPS = {
  fontWeight: 'normal',
};
